<div id="events" class="h-100">
  @if (selectedTab === EventTab.ScheduledEvents) {
    <phar-scheduled-events-list
      #scheduledEventsListComponent
      [isLocked]="isLocked$ | async"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp" />
    </phar-scheduled-events-list>
  } @else {
    <phar-adhoc-events-list
      #adhocEventsListComponent
      [isLocked]="isLocked$ | async"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp" />
    </phar-adhoc-events-list>
  }
</div>

<ng-template #switchEventsListTmp>
  <div class="d-flex align-items-center pt-3 px-4">
    <div class="ms-auto">
      <button
        class="left-switch-button cursor-pointer"
        mat-button
        [ngClass]="{ 'active-switch-button': selectedTab === EventTab.ScheduledEvents }"
        (click)="setActiveTab(EventTab.ScheduledEvents)">
        Scheduled Events
      </button>
      <button
        class="right-switch-button cursor-pointer"
        mat-button
        [ngClass]="{ 'active-switch-button': selectedTab === EventTab.AdhocEvents }"
        (click)="setActiveTab(EventTab.AdhocEvents)">
        Adhoc Events
      </button>
    </div>
    <div class="ms-auto">
      @if ((isLocked$ | async) === false) {
        <button class="ms-auto btn-small" mat-raised-button (click)="add()">
          <mat-icon svgIcon="plus" />
          <b>Add</b>
        </button>
      }
    </div>
  </div>
</ng-template>
