<div #eventsContainer class="d-flex overflow-hidden h-100">
  <div class="flex-1 w-50 component-border">
    <ng-content />
    @if (listView$ | async; as listView) {
      @if (listView.length) {
        <div class="px-3 mt-3">
          <mat-label class="me-auto font-weight-bold font-size-lg">Preview</mat-label>

          <div id="preview-scroll-holder" class="mt-4">
            <div id="preview-container" class="d-flex flex-row align-items-center list-view preview-list">
              <div class="d-flex list-view_list_headers no-copy">
                @for (event of listView; track $index) {
                  <div
                    class="list-view_list_element pointer text-center position-relative"
                    [id]="'preview-item-' + event.id"
                    [ngClass]="{
                      active: selectedEventId === event.id,
                      baseline: event.elementType === ElementType.Baseline,
                    }"
                    (click)="selectEvent(event)">
                    @if (event.isRepeatable) {
                      <mat-icon
                        class="recurring-event"
                        svgIcon="refresh"
                        pharTooltipPosition="above"
                        [pharTooltip]="event | pharRecurringEventTranslate"
                        [pharTooltipAdditionalClasses]="'tooltip-text-left'" />
                    }
                    <div
                      class="h-100 d-flex align-items-center mx-auto w-100"
                      pharTooltipPosition="above"
                      [pharTooltipDisabled]="event.elementType === ElementType.Baseline"
                      [pharTooltip]="event.eventName">
                      @if (event.elementType === ElementType.Baseline) {
                        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="play" />
                      }
                      <ng-template
                        [ngTemplateOutlet]="eventLineItem"
                        [ngTemplateOutletContext]="{ $implicit: event }" />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    }
    <div class="d-flex flex-row align-items-center">
      <div class="w-100 align-items-center">
        <mat-label class="me-auto px-4 font-weight-bold font-size-lg">Scheduled events</mat-label>
      </div>
      @if (!isLocked) {
        <div class="justify-content-end">
          <mat-toolbar class="background-transparent px-4">
            <phar-list-column-selection
              class="ms-3"
              [columns]="config.columns"
              [listComponent]="listViewComponent"
              (columnsSelectionChange)="columnsSelectionChange($event)" />
          </mat-toolbar>
        </div>
      }
    </div>

    <div id="events-list-container" class="px-3 flex-grow" pharScrollEndRecognizer (scrollEnd)="handleScrollEnd()">
      <phar-draggable-list-view
        #listViewComponent
        draggingDisabled="true"
        groupField="'elementType'"
        [config]="config"
        [dataSource]="listView$ | async"
        [footer]="(listView$ | async).length === 1 ? noEventsMsg : ''"
        [groupFieldValue]="ElementType.Group"
        [highlightedRecordId]="baselineEventId"
        [isRowSelectable]="true"
        [noDataMessage]="noEventsMsg"
        [selectedRecordId]="selectedEventId"
        (afterSelect)="selectEvent($event.dataItem)" />
    </div>
  </div>
  @if (editorShown) {
    <div class="widgets-container_content_rightbar scheduled-events-editor px-0 pb-0 position-relative">
      <div
        class="resize-handle"
        [class.resizing]="rightBarResizingEvent.isResizing"
        (mousedown)="startRightBarResizing($event)"></div>
      <button class="events-editor-close-icon" mat-icon-button (click)="closeEditor()">
        <mat-icon class="button-block_small-icon" svgIcon="close" />
      </button>
      <div class="d-flex justify-content-center p-3">
        <button
          class="left-switch-button cursor-pointer"
          mat-button
          [ngClass]="{ 'active-switch-button': activeEditorTab === EventsEditorTabEnum.Properties }"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Properties)">
          Properties
        </button>
        <button
          class="right-switch-button cursor-pointer"
          mat-button
          [ngClass]="{ 'active-switch-button': activeEditorTab === EventsEditorTabEnum.Notifications }"
          [disabled]="!selectedEventId"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Notifications)">
          Notifications ({{ numberOfEventNotifications$ | async }})
        </button>
      </div>
      <div class="flex-1 overflow-auto px-2">
        @switch (activeEditorTab) {
          @case (EventsEditorTabEnum.Properties) {
            <phar-scheduled-event-editor
              [opened]="editorShown"
              [disabled]="isLocked"
              (afterUpdate)="handleAfterUpdate($event)" />
          }
          @case (EventsEditorTabEnum.Notifications) {
            <phar-event-notifications-list [disabled]="isLocked" [event]="selectedEvent$ | async" />
          }
        }
      </div>
    </div>
  }
</div>

<ng-template #elementTypeTemplate let-element>
  <phar-event-type [elementType]="element.elementType" />
</ng-template>

<ng-template #startTemplate let-element>
  {{ element.start }}
  <ng-template [ngTemplateOutletContext]="{ $implicit: element.unit }" [ngTemplateOutlet]="unitTemplate" />
</ng-template>

<ng-template #beforeDeviationTemplate let-element>
  @if (element.beforeDeviation) {
    {{ element.beforeDeviation }}
    <ng-template
      [ngTemplateOutletContext]="{ $implicit: element.beforeDeviationUnit }"
      [ngTemplateOutlet]="unitTemplate" />
  }
</ng-template>

<ng-template #afterDeviationTemplate let-element>
  @if (element.afterDeviation) {
    {{ element.afterDeviation }}
    <ng-template
      [ngTemplateOutletContext]="{ $implicit: element.afterDeviationUnit }"
      [ngTemplateOutlet]="unitTemplate" />
  }
</ng-template>

<ng-template #isRepeatableTemplate let-element>
  @if (element.elementType !== ElementType.Baseline) {
    @switch (element.isRepeatable) {
      @case (true) {
        <span class="list-type-orange">Yes</span>
      }
      @case (false) {
        <span class="list-type-teal">No</span>
      }
    }
  }
</ng-template>

<ng-template #expandIconTemplate let-element>
  @if (element.type === FieldType.Group) {
    <button mat-icon-button>
      @if (element.isExpanded) {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-down" />
      } @else {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-right" />
      }
    </button>
  }
</ng-template>

<ng-template #actionsTemplate let-element>
  @if (!isLocked) {
    @if (element.elementType !== ElementType.Baseline) {
      <button
        class="ms-auto list-card_more"
        mat-icon-button
        [matMenuTriggerFor]="actionsMenu"
        (click)="$event.stopImmediatePropagation()">
        <mat-icon class="more">more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
        @for (button of rowActionBtns; track $index) {
          <button
            class="button-with-icon"
            mat-menu-item
            [disabled]="button.disabled"
            (click)="actionHandler({ eventName: button.eventName, dataItem: element })">
            @if (button.icon) {
              <mat-icon [svgIcon]="button.icon" />
            }
            <span>{{ button.title }}</span>

            @if (button.eventName === EventActions.Comments) {
              ({{ projectEventsCommentsCounters()[eventToProjectEventMap()[element.id]] || 0 }})
            }
          </button>
        }
      </mat-menu>
    }
  }
</ng-template>

<ng-template #unitTemplate let-unit let-value="value">
  <span>
    {{ value | pharTransformTimeUnit: unit : '' : '' }}
  </span>
</ng-template>

<ng-template #eventLineItem let-event>
  <div class="event-details">
    <div>
      {{ event.eventName | shorten: 15 : '...' : true }}
    </div>
    <div class="font-weight-normal text-center text-muted">
      {{ event.start | pharTransformTimeUnit: event.unit : '' : '' }}
    </div>
  </div>
</ng-template>
