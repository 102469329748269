import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { Observable } from 'rxjs';
import { checkCurrentProjectLockedState } from '../../project/store/project.actions';
import { EventModel } from '../event.model';
import { EventState, selectEventState, selectProjectEventFromListByEventId } from '../store/event.state';
import { filter, map, take } from 'rxjs/operators';
import { ProjectEventModel } from '../project-event.model';
import {
  createProjectEvent,
  dispatchedCreateUpdateEvent,
  pendingCreateUpdateEvent,
  successCreateUpdateEvent,
} from '../store/event.actions';
import { ScheduledEventsListComponent } from '../scheduled-events-list/scheduled-events-list.component';
import { AdhocEventsListComponent } from '../adhoc-events-list/adhoc-events-list.component';
import { UtilsService } from '../../core/utils.service';

export enum EventsEditorTabEnum {
  Properties,
  Notifications,
}

enum EventTab {
  ScheduledEvents,
  AdhocEvents,
}

@Component({
  templateUrl: 'events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  selectedTab = EventTab.ScheduledEvents;
  @ViewChild('scheduledEventsListComponent') scheduledEventsListComponent: ScheduledEventsListComponent;
  @ViewChild('adhocEventsListComponent') adhocEventsListComponent: AdhocEventsListComponent;
  isLocked$: Observable<boolean>;
  utilsService: UtilsService = inject(UtilsService);
  store: Store<AppState> = inject(Store);
  protected readonly EventTab = EventTab;

  setActiveTab(tabId: EventTab): void {
    this.selectedTab = tabId;
  }

  ngOnInit() {
    const selectedEvent = getState(this.store).event.current.projectEvent;
    if (ADHOC_EVENTS_TYPES.includes(selectedEvent?.event?.elementType)) {
      this.setActiveTab(EventTab.AdhocEvents);
    }
    this.isLocked$ = this.store.select(checkCurrentProjectLockedState);
  }

  navigateWhenDone(): void {
    this.store
      .select(selectEventState)
      .pipe(
        filter((state: EventState) => {
          const { pending, dispatched, success, projectEvent } = state.current;

          return (
            !pending && !dispatched && success && !!state.listProjectEvents.find(item => item.id === projectEvent.id)
          );
        }),
        take(1),
      )
      .subscribe({
        next: res => {
          if (this.selectedTab === EventTab.ScheduledEvents) {
            this.scheduledEventsListComponent.selectEvent(res.current.projectEvent.event);
            return;
          }

          this.adhocEventsListComponent.handleEdit(res.current.projectEvent.event.id);
        },
      });
  }

  duplicateEvent(event: EventModel): void {
    this.store
      .select(selectProjectEventFromListByEventId({ eventId: event.id }))
      .pipe(
        map(projectEvent => {
          const event: EventModel = projectEvent.event;
          const newEvent: EventModel = {
            ...event,
            id: null,
            eventName: this.generateUniqueEventName(event),
          };

          const newProjectEvent: ProjectEventModel = {
            ...projectEvent,
            id: null,
            eventId: null,
            event: newEvent,
          };

          return newProjectEvent;
        }),
        take(1),
      )
      .subscribe(projectEvent => {
        this.utilsService.dispatchActions(this.store, [
          successCreateUpdateEvent({ success: false }),
          pendingCreateUpdateEvent({ pending: true }),
          dispatchedCreateUpdateEvent({ dispatched: true }),
          createProjectEvent({ projectEvent }),
        ]);
        this.navigateWhenDone();
      });
  }

  add(): void {
    if (this.selectedTab === EventTab.AdhocEvents && this.adhocEventsListComponent) {
      this.adhocEventsListComponent.addNewEvent();
      return;
    }

    if (this.selectedTab === EventTab.ScheduledEvents && this.scheduledEventsListComponent) {
      this.scheduledEventsListComponent.addEvent();
    }
  }

  private generateUniqueEventName(event: EventModel) {
    const list: EventModel[] = getState(this.store).event.listProjectEvents.map(projectEvent => projectEvent.event);
    let newName = event.eventName;
    while (list.some(item => item.eventName.toLowerCase().trim() === newName.toLowerCase().trim())) {
      newName = this.replaceCopy(newName);
    }
    return newName;
  }

  replaceCopy(name: string): string {
    const regex = /\(copy\s(\d+)\)/i;
    const match = name.match(regex);
    if (match) {
      const number = parseInt(match[1], 10) + 1;
      return name.replace(regex, `(copy ${number})`);
    } else {
      return `${name} (copy 1)`;
    }
  }
}
