<aside class="group-editor-container">
  <h3 class="mb-4">{{ event.id ? 'Update' : 'New' }} Adhoc Event</h3>

  <phar-adhoc-event-editor-form
    [event]="event"
    [disabled]="disabled"
    (formDataChanged)="handleFormDataChange($event)" />

  <div class="mt-2">
    <button mat-raised-button class="ms-auto btn-small me-3" [disabled]="disabled" (click)="closeEditor()">
      <mat-icon [svgIcon]="'close'" />
      <b>Discard</b>
    </button>

    <button
      mat-raised-button
      class="ms-auto btn-small button-success"
      [disabled]="!(formData$ | async).isValid || disabled"
      (click)="saveEvent()">
      <mat-icon [svgIcon]="'check'" />
      <b>{{ event.id ? 'Update' : 'Save' }}</b>
    </button>
  </div>
</aside>
