import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { IEventNotificationModel } from '../event-notification.model';
import { EventNotificationsService } from '../event-notifications.service';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { DateTimeUnitEnum } from '../../enums/datetime-unit.enum';
import { AppState } from '../../../store/models/app.state';
import { BaseComponent } from '../../../shared/base.class';
import { loadEventNotificationsListByEventId } from '../../store/event.actions';
import { FormControls } from '../../../shared/types/form-controls.type';
import { UserRoleEnum } from '../../../user/user-roles.enum';
import { TimingOptions } from '../../../shared/models/timing-options.enum';

type NotificationFormGroup = FormControls<
  Omit<IEventNotificationModel, 'eventId' | 'id' | 'shouldNotifyResponsePerson' | 'userIds'>
>;

@Component({
  selector: 'phar-event-notification',
  templateUrl: 'event-notification.component.html',
  styleUrls: ['event-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNotificationComponent extends BaseComponent implements OnInit, OnDestroy {
  form: FormGroup<NotificationFormGroup>;
  notification: IEventNotificationModel;
  isOpenState = false;

  hasPendingChanges$: Observable<boolean>;
  notification$ = new BehaviorSubject<IEventNotificationModel>(null);
  isNewNotification = true;

  readonly userRoles: UserRoleEnum[] = [UserRoleEnum.Coordinator, UserRoleEnum.Investigator, UserRoleEnum.Participant];
  readonly userRolesMap: Record<UserRoleEnum, string> = {
    [UserRoleEnum.Coordinator]: 'Coordinator',
    [UserRoleEnum.Investigator]: 'Investigator',
    [UserRoleEnum.Participant]: 'Participant',
  };
  readonly timingOptions = [
    {
      label: 'Before',
      value: TimingOptions.Before,
    },
    {
      label: 'During',
      value: TimingOptions.During,
    },
    {
      label: 'After',
      value: TimingOptions.After,
    },
  ];
  readonly timeUnitDisplayOptions: DropdownModel[] = [
    {
      label: 'day(s)',
      value: DateTimeUnitEnum.Day,
    },
    {
      label: 'week(s)',
      value: DateTimeUnitEnum.Week,
    },
    {
      label: 'month(s)',
      value: DateTimeUnitEnum.Month,
    },
    // {
    //   label: 'year(s)',
    //   value: DateTimeUnitEnum.Year, @ТОДО This option is current not available in the other system so it should stay commented for now
    // }
  ];
  private _disabled = false;
  @Output() createNewNotification = new EventEmitter<IEventNotificationModel>();
  @Output() deleteNewNotification = new EventEmitter();

  constructor(
    private readonly eventNotificationsService: EventNotificationsService,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  @Input({ required: true })
  set eventNotification(notification: IEventNotificationModel) {
    const roleTypesNotification: IEventNotificationModel = {
      ...notification,
      roleTypes: notification.roleTypes || [UserRoleEnum.Participant],
    };

    this.notification = roleTypesNotification;
    this.notification$.next(roleTypesNotification);
    this.isNewNotification = typeof this.notification.id !== 'number';
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;

    if (!this.form) {
      return;
    }

    if (this.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnInit() {
    this.form = this.getInitialFormGroup();
    this.form.patchValue(this.notification);
    this.handleTimingChange(this.notification.timing);

    this.hasPendingChanges$ = combineLatest([
      this.notification$,
      this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
    ]).pipe(
      map(([notification, formValue]) => {
        return !isEqual(
          {
            ...notification,
            roleTypes: notification.roleTypes?.sort(),
          },
          {
            ...notification,
            ...formValue,
            roleTypes: formValue.roleTypes?.sort(),
          },
        );
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    );

    if (this.form.invalid) {
      this.isOpenState = true;
    }

    if (this.disabled) {
      this.form.disable();
    }

    this.initListeners();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.notification$.complete();
  }

  saveNotification(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const notification: IEventNotificationModel = {
      ...this.notification,
      ...this.form.getRawValue(),
    };

    if (this.isNewNotification) {
      this.createNewNotification.emit(notification);
      return;
    }

    this.eventNotificationsService
      .update(notification)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: notification.eventId }));
        },
      });
  }

  deleteNotification(): void {
    if (this.isNewNotification) {
      this.deleteNewNotification.emit();
      return;
    }

    this.eventNotificationsService
      .delete(this.notification.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: this.notification.eventId }));
        },
      });
  }

  toggleOpenCloseState(): void {
    this.isOpenState = !this.isOpenState;
  }

  discardChanges(): void {
    this.form.patchValue(this.notification);
  }

  private getInitialFormGroup(): FormGroup<NotificationFormGroup> {
    return new FormGroup<NotificationFormGroup>({
      timing: new FormControl(null, [Validators.required]),
      number: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      specificTime: new FormControl(null, [Validators.required]),
      customMessage: new FormControl(null, [Validators.required]),
      roleTypes: new FormControl([UserRoleEnum.Participant], [Validators.required]),
    });
  }

  private initListeners(): void {
    this.form.controls.timing.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.handleTimingChange(value);
      },
    });
  }

  private handleTimingChange(value: string): void {
    if (value === TimingOptions.During) {
      this.disableNumberAndUnit();
    } else {
      this.form.controls.number.enable();
      this.form.controls.unit.enable();
    }
  }

  private disableNumberAndUnit(): void {
    this.form.controls.number.disable();
    this.form.controls.number.setValue(null);
    this.form.controls.unit.disable();
    this.form.controls.unit.setValue(null);
  }
}
