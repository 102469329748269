<div class="event-notification" [ngClass]="{ 'event-notification_pending-changes': hasPendingChanges$ | async }">
  <div class="d-flex justify-content-between align-items-center">
    <span class="d-flex align-items-center cursor-pointer" (click)="toggleOpenCloseState()">
      <mat-icon class="cursor-pointer header-icon" [svgIcon]="isOpenState ? 'arrow-down' : 'arrow-right'" />

      <span class="me-auto ms-2 text-truncate fw-bold notification-title">
        <ng-content select="[notificationTitle]" />
      </span>
    </span>
    @if (!disabled) {
      <mat-icon
        class="cursor-pointer editor-trash-icon ms-auto header-icon"
        svgIcon="trash"
        matTooltip="Remove notification"
        (click)="deleteNotification()" />
    }
  </div>

  @if (isOpenState) {
    <form [formGroup]="form">
      <div>
        <mat-label>Notify Users</mat-label>
        <mat-form-field class="w-100">
          <mat-select formControlName="roleTypes" placeholder="Select users" multiple>
            @for (role of userRoles; track $index) {
              <mat-option [value]="role">
                <span>{{ userRolesMap[role] }}</span>
              </mat-option>
            }
          </mat-select>
          <mat-error> Notify users are <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-label class="required-field">When</mat-label>

        <mat-form-field class="w-100">
          <mat-select formControlName="timing" placeholder="Select when">
            @for (timingOption of timingOptions; track $index) {
              <mat-option [value]="timingOption.value">
                <span>{{ timingOption.label }}</span>
              </mat-option>
            }
          </mat-select>

          <mat-error> When is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>

      <div class="row gx-0">
        <div class="col-6 pe-1">
          <mat-label class="required-field">Number</mat-label>
          <mat-form-field
            class="w-100"
            [ngClass]="{ 'mb-4': form.get('number').touched && form.get('number').invalid }">
            <input matInput type="number" placeholder="Number" formControlName="number" />

            <mat-error> Number is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 ps-1">
          <mat-label class="required-field">Time Unit</mat-label>

          <mat-form-field class="w-100" [ngClass]="{ 'mb-4': form.get('unit').touched && form.get('unit').invalid }">
            <mat-select formControlName="unit" placeholder="Unit">
              @for (timeUnit of timeUnitDisplayOptions; track $index) {
                <mat-option [value]="timeUnit.value">
                  <span>{{ timeUnit.label }}</span>
                </mat-option>
              }
            </mat-select>

            <mat-error> Time unit is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <mat-label class="required-field">Specific Time</mat-label>
        <mat-form-field class="time-picker-field">
          <phar-timepicker-field
            formControlName="specificTime"
            [withPeriod]="false"
            [autoNavigateToMinutes]="true"
            [showSeconds]="false" />
          <mat-icon matSuffix>access_time</mat-icon>
          <mat-error> Specific time is <b>required</b> </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-label class="required-field">Message</mat-label>
        <mat-form-field class="w-100">
          <input matInput placeholder="Type message" formControlName="customMessage" />

          <mat-error> Message is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="d-flex justify-content-between">
      <button
        mat-raised-button
        class="btn-small"
        [disabled]="(hasPendingChanges$ | async) === false"
        (click)="discardChanges()">
        <mat-icon svgIcon="close" />
        Discard
      </button>

      <button
        mat-raised-button
        class="btn-small button-success"
        [disabled]="(hasPendingChanges$ | async) === false"
        (click)="saveNotification()">
        <mat-icon svgIcon="check" />
        Save
      </button>
    </div>
  }
</div>
