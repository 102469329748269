import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProjectEventModel } from '../project-event.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { UtilsService } from '../../core/utils.service';
import { select, Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import { distinctUntilChanged, filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import {
  changeCurrentProjectEvent,
  deleteProjectEvent,
  getProjectEventsCommentsCounter,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  resetCurrentProjectEvent,
  resetEventNotificationsList,
  resetProjectEventList,
  updateEditor,
} from '../store/event.actions';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { EventGroupModel, EventModel } from '../event.model';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { FieldType } from '../../shared/draggable-list-view/models/group-model';
import { EventActions } from '../enums/event-actions.enum';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { EventsEditorTabEnum } from '../events/events.component';
import { selectCurrentEventProjectEvent, selectListEventNotificationsState } from '../store/event.state';
import { environment } from '../../../environments/environment';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { MatDialog } from '@angular/material/dialog';
import { EventCommentDialogComponent } from '../event-comments/event-comment.dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectEventsCommentsCounter } from '../store/event.reducer';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';

@Component({
  selector: 'phar-scheduled-events-list',
  templateUrl: './scheduled-events-list.component.html',
  styleUrls: ['./scheduled-events-list.component.scss'],
})
export class ScheduledEventsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('expandIconTemplate', { static: true }) expandIconTemplate: TemplateRef<any>;
  @ViewChild('startTemplate', { static: true }) startTemplate: TemplateRef<any>;
  @ViewChild('beforeDeviationTemplate', { static: true }) beforeDeviationTemplate: TemplateRef<any>;
  @ViewChild('afterDeviationTemplate', { static: true }) afterDeviationTemplate: TemplateRef<any>;
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('eventTypeTemplate', { static: true }) eventTypeTemplate: TemplateRef<any>;
  @ViewChild('isRepeatableTemplate', { static: true }) isRepeatableTemplate: TemplateRef<any>;
  @ViewChild('eventsContainer', { static: true }) eventsContainer: ElementRef;
  @Input() isLocked = false;
  @Output() duplicateEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  editorShown: boolean;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;

  selectedEventId: number;
  baselineEventId: number;
  noEventsMsg = 'Please click Add above on the right to start creating study events.';
  listView$: Observable<EventModel[]>;
  selectedEvent$: Observable<EventModel>;
  numberOfEventNotifications$: Observable<number>;
  projectId: null;
  injector: Injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  projectEvents = toSignal(
    this.store.select(state => state.event.listProjectEvents),
    { injector: this.injector },
  );
  projectEventsCommentsCounters = toSignal(this.store.select(selectEventsCommentsCounter), { injector: this.injector });
  eventToProjectEventMap = computed(() => {
    const eventToProjectEventMapping = {};
    this.projectEvents().forEach(projectEvent => {
      // its needed because we are using projectEvent.id for comments,but in the actions template we are receiving event.id
      eventToProjectEventMapping[projectEvent.eventId] = projectEvent.id;
    });
    return eventToProjectEventMapping;
  });
  config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'eventName',
        title: 'Event Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: false,
        selectable: true,
        template: null,
      },
      {
        field: 'start',
        title: 'Start',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'beforeDeviation',
        title: 'Window Before',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'afterDeviation',
        title: 'Window After',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'elementType',
        title: 'Event Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'isRepeatable',
        title: 'Recurring',
        size: '70px',
        // Add as many pixels as left margin added for nested columns (2rem).
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        template: null,
      },
    ],
    actions: [],
    customClasses: ['events-list'],
  };

  rowActionBtns = [
    {
      title: 'Edit',
      eventName: EventActions.Edit,
      icon: 'edit',
    },
    {
      title: 'Duplicate',
      eventName: EventActions.Duplicate,
      icon: 'duplicate',
    },
    {
      title: 'Comments',
      eventName: EventActions.Comments,
      icon: 'comment',
    },
    {
      title: 'Delete',
      eventName: EventActions.Delete,
      icon: 'trash',
    },
  ];

  eventActionsFnMap = {
    [EventActions.Edit]: this.handleEditEvent,
    [EventActions.Delete]: this.handleDeleteEvent,
    [EventActions.Duplicate]: this.handleDuplicateEvent,
    [EventActions.Comments]: this.openCommentsDialog,
  };

  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };

  readonly ElementType = ElementTypeEnum;
  readonly FieldType = FieldType;
  readonly EventsEditorTabEnum = EventsEditorTabEnum;
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private utilsService: UtilsService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.selectedEventId = getState(this.store).event.current.projectEvent.event.id;

    this.store
      .pipe(
        select(state => state.project.current.project.id),
        filter(id => !!id),
        takeUntil(this.destroy$),
      )
      .subscribe(id => {
        this.store.dispatch(loadProjectEventListByProjectId({ id: id }));
      });

    this.selectedEvent$ = this.store.select(selectCurrentEventProjectEvent);
    this.numberOfEventNotifications$ = this.store
      .select(selectListEventNotificationsState)
      .pipe(map(eventNotifications => eventNotifications.length));
    this.listView$ = this.store
      .select(state => state.event.listProjectEvents)
      .pipe(
        map(projectEventList => {
          return projectEventList
            .filter((projectEvent: ProjectEventModel) => projectEvent.event !== null)
            .map((projectEvent: ProjectEventModel) => {
              if (projectEvent.isBaseline) {
                this.baselineEventId = projectEvent.eventId;
              }

              return { ...projectEvent.event };
            });
        }),
        map((events: EventModel[]) =>
          events.filter((event: EventModel) => !ADHOC_EVENTS_TYPES.includes(event.elementType)),
        ),
        shareReplay(1),
      );

    this.loadEventNotificationsOnSelectEvent();
  }

  ngAfterViewInit(): void {
    const templates = {
      expand: this.expandIconTemplate,
      eventType: this.eventTypeTemplate,
      elementType: this.elementTypeTemplate,
      afterDeviation: this.afterDeviationTemplate,
      beforeDeviation: this.beforeDeviationTemplate,
      start: this.startTemplate,
      isRepeatable: this.isRepeatableTemplate,
      userIdCreated: this.actionsTemplate, //userIdCreated is user as a placeholder only
    };

    if (this.isLocked) {
      delete templates.userIdCreated;
      this.config.columns = this.config.columns.filter(item => item.field !== 'userIdCreated');
    }

    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.store.dispatch(resetProjectEventList());
    this.store.dispatch(resetCurrentProjectEvent());
  }

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  actionHandler($event: { eventName: EventActions; dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    try {
      this.eventActionsFnMap[eventName].bind(this)(dataItem);
    } catch (e) {
      console.error(`Method not implemented ${eventName}`, e);
    }
  }

  handleEditEvent(dataItem: EventGroupModel): void {
    this.store.dispatch(changeCurrentProjectEvent({ eventId: dataItem.id }));
    this.selectedEventId = dataItem.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
  }

  handleDeleteEvent(dataItem: EventGroupModel): void {
    this.confirmationService
      .openConfirmDialog('Do you want to remove ' + dataItem.eventName + '?')
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe(() => {
        const projectEventId = getState(this.store).event.listProjectEvents.find(x => x.eventId == dataItem.id).id;
        const currentsSelected = getState(this.store).event.current.projectEvent.id;
        if (projectEventId === currentsSelected) {
          this.store.dispatch(resetCurrentProjectEvent());
          this.editorShown = false;
          this.selectedEventId = null;
          this.store.dispatch(updateEditor());
        }
        this.store.dispatch(deleteProjectEvent({ id: projectEventId }));
      });
  }

  handleDuplicateEvent(event: EventGroupModel): void {
    this.duplicateEvent.emit(event);
  }

  openCommentsDialog(event: EventModel): void {
    const projectEvent = this.projectEvents().find(item => item.eventId === event.id);
    if (!projectEvent) {
      return;
    }
    this.dialog
      .open(EventCommentDialogComponent, {
        data: {
          projectEventId: projectEvent.id,
        },
        ...COMMENTS_DIALOG_CONFIG,
      })
      .afterClosed()
      .pipe(
        filter(shouldRefreshCounters => !!shouldRefreshCounters),
        take(1),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(getProjectEventsCommentsCounter());
        },
      });
  }

  handleAfterUpdate({ update }: { update: boolean }) {
    if (!update) {
      this.addEvent(false);
    }
  }

  addEvent(reset = true): void {
    if (reset) {
      this.store.dispatch(resetCurrentProjectEvent());
    }
    const selectedEvent = getState(this.store).event.current.projectEvent.event;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.selectedEventId = selectedEvent.id;
    this.scrollToElement(this.selectedEventId);
  }

  selectEvent(event: EventModel): void {
    if (event.elementType === this.ElementType.Baseline || event.id === this.selectedEventId) {
      this.selectedEventId = null;
      this.editorShown = false;
      this.scrollToElement(event.id);
      return;
    }

    this.store.dispatch(changeCurrentProjectEvent({ eventId: event.id }));
    this.selectedEventId = event.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.scrollToElement(event.id);
  }

  handleScrollEnd() {
    if (!this.selectedEventId) {
      return;
    }
    this.scrollToElement(this.selectedEventId, true, 'preview-item-');
  }

  scrollToElement(elementId: number, scrollIntoPreviewContainer = false, prefix = 'item-'): void {
    if (!elementId) {
      return;
    }
    try {
      this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {
        if (scrollIntoPreviewContainer) {
          const container = document.getElementById('preview-container');
          if (!container) {
            return;
          }
          container.scrollLeft = element.offsetLeft - element.clientWidth * 3;
          return;
        }

        (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });
      });
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
    }
  }

  closeEditor() {
    this.editorShown = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
  }

  setActiveEditorTab(tab: EventsEditorTabEnum): void {
    this.activeEditorTab = tab;
  }

  private loadEventNotificationsOnSelectEvent(): void {
    this.selectedEvent$
      .pipe(
        map(selectedEvent => selectedEvent.id),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(selectedEventId => {
        this.setActiveEditorTab(EventsEditorTabEnum.Properties);
        if (selectedEventId) {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: selectedEventId }));
        } else {
          this.store.dispatch(resetEventNotificationsList());
        }
      });
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.eventsContainer.nativeElement).getPropertyValue('--rightBarWidth'), 10);
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.eventsContainer.nativeElement.style.setProperty('--rightBarWidth', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  protected readonly EventActions = EventActions;
}
